i.grid-input-icon {
  position: relative;
  margin-left: -22px;
}

.grid-popup-container {
  background: #fff;
  border: 1px solid black;
  padding: 5px;
}

.grid-popup-container.grid_time_period_container {
  height: 70px;
  max-width: 250px;
}

.time_period_shorthand input {
  margin-left: -22px;
}

.grid-popup-container.grid_dow_container {
  max-width: 270px;
  height: 70px;
}

.dow_shorthand input, .dow_select .btn-group {
  margin-left: -18px;
}

.grid-popup-container.grid_date_range_container {
  height: 70px;
  max-width: 250px;
}

.date_range_shorthand {
  margin-left: -6px;
}

.date_range_shorthand input {
  width: 100px;
}

.date_range_output input {
  width: 200px;
  text-align: center;
}

.grid-popup-container.grid_coordinate_container {
  height: 70px;
  max-width: 250px;
}

.coordinate_shorthand {
  margin-left: -6px;
}

.coordinate_shorthand input {
  width: 100px;
}

.coordinate_output input {
  width: 150px;
  text-align: center;
}

.hide-column a {
  padding: 2px 20px !important;
  font-size: 12px;
}

.hide-column .hidden-column {
  background: #bbb;
  color: #777;
}

.hide-column .hidden-column:hover, .hide-column .hidden-column:focus {
  background: #ccc;
  color: #777;
}

.hide-column-demos-tv a {
  padding: 2px 20px !important;
  font-size: 12px;
}

.hide-column-demos-tv .hidden-column {
  background: #bbb;
  color: #777;
}

.hide-column-demos-tv .hidden-column:hover, .hide-column-demos-tv .hidden-column:focus {
  background: #ccc;
  color: #777;
}

.hide-column-demos-radio a {
  padding: 2px 20px !important;
  font-size: 12px;
}

.hide-column-demos-radio .hidden-column {
  background: #bbb;
  color: #777;
}

.hide-column-demos-radio .hidden-column:hover, .hide-column-demos-radio .hidden-column:focus {
  background: #ccc;
  color: #777;
}

.worksheet-panel .panel-heading {
  line-height: 16px;
  font-size: 16px;
  padding: 5px 20px;
  border-bottom: 1px solid rgba(241, 89, 37, .65);
  cursor: pointer;
}

.worksheet-panel .panel-heading img {
  width: 16px;
  height: 16px;
  margin-bottom: 5px;
}

.worksheet-panel .panel-body {
  border: 1px solid rgba(241, 89, 37, .65);;
  border-top: 0;
}

.worksheet-market {
  display: inline-block;
  width: 100%;
}

.worksheet-info {
  padding: 5px 20px;
}

.worksheet-totals-container {
  margin: -25px -25px 10px;
  background-color: #f15925;
  color: #fff;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, .2);
  -moz-box-shadow: 0 4px 4px -2px rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 4px 4px -2px rgba(0, 0, 0, .2);
}

.worksheet-totals-container.fixed {
  display: block;
  position: fixed;
  top: 85px;
  width: calc(~'100% - 50px');
  z-index: 995;
  right: 15px;
}

.worksheet-totals-container > .col-xs-12 {
  padding: 0;
}

.worksheet-totals {
  margin-right: 0;
  margin-left: 0;
}

.worksheet-totals .total {
  border-color: #fff;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 12px;
  line-height: 22px;
}

.worksheet-market-ratings-container {
  background-color: rgba(241, 89, 37, 0.5);
  color: #2b2b2b;
  border-color: #2b2b2b;
  font-weight: 800;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 20px;
}

.ws-custom-select {
  padding: 0 !important;
  height: 20px !important;
  font-size: 12px !important;
  border-radius: 0 !important;
  border: 0 !important;
  outline: 1px inset #2b2b2b !important;
  color: #2b2b2b !important;
  outline-offset: -1px !important;
  width: 100% !important;
}

.grid-popup-container-alert .alert {
  padding: 1px 2px;
  text-align: center;
  font-size: 11px;
}

#market_select li a, .hide-column li a, .hide-media li a, #wsSaveInterval li a {
  cursor: pointer !important;
}

.media-totals-toggle {
  width: 100%;
  height: 20px;
  background-color: #373737 !important;
  color: #f7fafc !important;
  border-bottom: none !important;
  font-weight: 600 !important;
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;

}

.media-totals-wrapper .col-xs-12 {
  height: 100%;
}

.media-totals {
  height: 100%;
  background-color: #ddd;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #373737;
  border-top: 0;
}

.media-totals table {
  table-layout: fixed;
}

.media-totals table thead {
  background: #2b2b2b !important;
  border-bottom: none !important;
  font-weight: 600 !important;
  font-size: 10px;
  text-transform: uppercase;
  height: 20px;
}

.media-totals table thead th {
  padding: 0 !important;
  padding-top: 5px;
  color: #f7fafc !important;
  text-align: center;
}

.media-totals .table-wrapper {
  padding: 5px 10px;
}

.media-totals .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fff !important;
}

.media-totals .table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f6f6f6 !important;
}

.media-totals .table-striped > tbody td {
  font-size: 11px;
  padding: 2px;
  text-align: center;
}

.media-totals .table-striped > tbody td:first-child {
  text-align: left;
  font-weight: 800;
}

.media-totals table tfoot {
  background: #f15925 !important;
  border-bottom: none !important;
  font-size: 10px;
  text-transform: uppercase;
  height: 20px;
}

.media-totals table tfoot td {
  color: #f7fafc !important;
  text-align: center;
  padding: 2px;
}

.media-totals .table-striped > tfoot td:first-child {
  text-align: left;
  font-weight: 800;
}

.ws-ag-container {
  border: 1px #373737 solid !important;
  border-bottom: 0 !important;
}

.hide-media a {
  padding: 2px 20px !important;
  font-size: 12px;
}

.hide-media .hidden-media {
  background: #bbb;
  color: #777;
}

.hide-media .hidden-media:hover, .hide-media .hidden-media:focus {
  background: #ccc;
  color: #777;
}

#session-timeout-dialog .progress {
  height: 14px;
}

#session-timeout-dialog .progress-bar {
  font-size: 12px;
  line-height: 14px;
  padding-top: 1px;
  background-color: #f15925;
}

.hide-media a {
  padding: 2px 20px !important;
  font-size: 12px;
}

#wsSaveInterval a {
  padding: 2px 20px !important;
  font-size: 12px;
  color: #777;
}

#wsSaveInterval .selected {
  background: #f8ac92;
  color: #2b2b2b;
}

#wsSaveInterval .selected:hover, #wsSaveInterval .selected:focus {
  background: #f8ac92;
  color: #2b2b2b;
}

#wsSaveInterval .divider {
  margin: 2px 0 !important;
}

#wsSaveInterval li.since_last a {
  cursor: default !important;
}

#wsSaveInterval li a[data-verbose="true"] {
  color: #f15925;
}

.worksheet-grid .ag-header-cell[colId="row_select"] span[ref="eMenu"] {
  display: none !important;
}

.duplicate-row .dropdown-menu a {
  padding: 2px 20px !important;
  font-size: 12px;
  color: #777;
  cursor: pointer !important;
}

#wsVersionDropdown .dropdown-menu a {
  padding: 2px 20px !important;
  font-size: 12px;
  color: #777;
  cursor: pointer !important;
}

.media-totals table tfoot {
  background: #f15925 !important;
  border: none !important;
  font-weight: 600 !important;
  font-size: 11px;
  text-transform: uppercase;
  height: 20px;
}
#RatingsRankerModal{
  .ratings-analysis-container .ag-bootstrap .ag-group-cell {
    font-style: normal !important;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row .ag-header-group-cell-with-group {
    border-left: 1px solid #fff;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row .ag-header-cell {
    border-right: 0 !important;
    border-left: 1px solid #fff;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row:nth-child(1) .ag-header-group-cell-with-group {
    background-color: #f26a3d !important;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row:nth-child(2) .ag-header-group-cell-with-group {
    background-color: #f28e6d !important;
  }

  .ratings-analysis-container .ag-header-container .ag-header-group-cell-label {
    text-align: center;
  }

  .ratings-analysis-container .ag-row-level-0 {
    //background-color: #cccccc !important;
    color: #2b2b2b !important;
  }

  .ratings-analysis-container .ag-row-level-1 {
    background-color: #d4d4d4 !important;
    color: #2b2b2b !important;
  }

  .ratings-analysis-container .ag-row-level-1:nth-child(2n) {
    background-color: #d9d9d9 !important;
  }

  .ratings-analysis-container .ag-row-level-2 {
    background-color: #e5e5e5 !important;
    color: #2b2b2b !important;
  }

  .ratings-analysis-container .ag-row-level-2:nth-child(2n) {
    background-color: #ededed !important;
  }

  .ratings-analysis-container .ag-row-level-2.ag-row-group-expanded {
    background-color: #f28e6d !important;
    color: #fff;
  }

  .program-list {
    display: inline-block;
    margin-right: -15px;
    width: calc(100% - 15px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .program-modal-launch {
    display: inline-block;
    float: right;
    cursor: pointer;
  }

  .program-modal-launch i {
    color: #f26a3d;
  }

  /*.program-calendar tbody > tr > td{*/
  /*vertical-align: top;*/
  /*border-left: 1px solid #2b2b2b;*/
  /*border-bottom: 1px solid #2b2b2b;*/
  /*}*/

  .program-calendar.table > thead > tr > th {
    padding: 2px 0;
  }

  .ag-header-group-cell {
    border-left: 1px solid #fff;
  }

  .ag-header-row .ag-header-group-cell:nth-child(1) {
    border-left: 0;
  }

  .tv_ranker_header li {
    display: inline-block;
    line-height: 30px;
    margin-left: 5px;
  }

  .tv_ranker_header li i {
    margin: 0;
  }

  .tv_ranker_header h3 {
    margin: 0;
  }
}