// Bootstrap
@import "bootstrap";

// Vendors
@import "../../css/dataTables.bootstrap.min.css";
@import "../../css/sweetalert.css";
@import "../../css/select2.css";
@import "../../css/metisMenu.min.css";
@import "../../css/jquery.toast.css";
@import "bootstrap-select";
@import "timepicker";
@import "datepicker3";
@import "../../css/daterangepicker.css";
@import "../../css/daterangepicker2.css";
@import "vendor/nestable";
@import "../../css/switchery.css";
@import "vendor/bootstrap-clockpicker";
@import "vendor/jquery.ellipsis.less";
@import "../../css/wickedpicker.min.css";
@import "../../css/dropzone.min.css";
@import "cropper.less";
@import "font-awesome";
@import "simple-line-icons";
@import "weather-icons";
@import "../../css/highcharts.css";
@import "../../css/noty.css";
@import "../../css/offline-language-english.css";
@import "../../css/offline-theme-slide.css";
@import "../../css/multi-select.css";
@import "../../css/jquery.mmenu.all.css";
@import "../../css/dropify.css";
@import "../../css/bootstrap-submenu.css";

// EliteAdmin
@import "theme/variables";
@import "theme/eliteadmin";
@import "theme/widgets";
@import "theme/sidebar-nav";
@import "icons/themify-icons/themify-icons";
@import "icons/linea-icons/linea.less";
@import "theme/pages";
@import "theme/responsive";
@import "theme/spinners";
@import "theme/animate";
@import "theme/wizard";
@import "theme/ribbons";

// EliteAdmin Overrides
@import "colors";
@import "base/theme-overrides";
@import "base/select2-overrides";
@import "base/ag-overrides";

// App
@import "base/calendar-grid";
@import "app/main";
@import "app/advertisers";
@import "app/campaigns";
@import "app/dayparts";
@import "app/invoices";
@import "app/ratings";
@import "app/logos";
@import "app/auth";
@import "app/form-wizard";
@import "app/charts";
@import "app/worksheet";
@import "app/side-nav";
@import "app/flight-weeks";
@import "app/product";

// Widgets
@import "widgets/calendar";
@import "widgets/calculator";
@import "widgets/weather";

@icon-font-path: "../../fonts/";
@fa-font-path: "../../fonts";
@simple-line-font-path: "../../fonts/";
@wi-path: "../../fonts";
