.dow-container button {
  font-family: monospace;
}

.ui-autocomplete {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  width: max-content;
}

.ui-autocomplete .ui-menu-item {
  padding: 8px 8px;

  cursor: pointer;
  list-style-type: none;
  margin-top: 6px;
  margin-bottom: 6px;

}

.ui-autocomplete .ui-menu-item:hover {
  background-color: #f2f2f2;
  border-radius: 4px;
  width:auto;
  margin-right: 7px;
}

.disabled {
  pointer-events: none;
  color: #777; /* Puedes ajustar el color según tu preferencia */
}